import Upsell from 'modules/landingPages/pages/Upsell/UpsellContent';
import env from 'environments';
import offerImage from './seller-guide-orig.png';

const isProduction = env.ENVIRONMENT === 'production';

const sellerGuideDefinitions = {
  offerDescription: [
    '12 Ready to Go Seller Guides that you can give to sellers.',
    'Easily customize the guides to you by simply stapling your business card on the front cover.',
    'Show sellers you’re a Competent Professional with these guides.',
    'Demonstrate that you care about marketing with these guides.',
    'Prove your value with all of the details and in-depth information inside the guides.',
  ],
  productHandler: 'sellerguide',
  componentId: 'handle:clickfunnel-7',
  summaryTitle: 'Seller Guide (12 Printed Guides)',
  summaryPrice: '$7',
  offerImage,
  upsell: {
    productHandler: 'authorify-monthly-non-contracted',
    productPricePointHandle: 'monthly_trial_0',
    componentId: isProduction ? '1824465' : '2197557',
    component: () => <Upsell productName="Seller Guide" />,
    offerId: isProduction ? '65047cc3b8cad4bdeb9f204e' : '64dd0b62a92b674c230273b4',
  },
  USOnly: true,
};

export default sellerGuideDefinitions;

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet';
import { get } from 'lodash-es';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';

import { isFailed, isLoading, isSucceeded } from 'store/status';
import { fetchUserCredits } from 'modules/api';
import { useCustomerPlanInfo } from 'modules/v2/utils';
import { CheckIcon, OrderIcon, PublishIcon } from 'modules/v2/common/components/SvgIcons';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { InsuficientCreditsModal } from 'modules/v2/common/AtomicDesign/organisms';
import { GenerateDigitalBookModal } from 'modules/v2/draft/components';
import { getRouteOrderCheckout, getRouteDraftView } from 'modules/v2/draft/routes/navigation';
import { ArrowLeft } from 'modules/v2/common/components/SvgIcon/ArrowLeft/index';
import { MINIMUM_CHECKOUT_BOOK_QUANTITY } from 'modules/v2/pages/constants';
import { postJourneyTodos } from 'modules/api/common';
import useTodos from 'modules/v2/context/JourneyContextReducer';
import { getData } from 'modules/auth';
import {
  withBook,
  withCover,
  withDraft,
  withImageLibrary,
  withPreferences,
} from 'modules/v2/editor/containers';
import { isPreviewStatusReady } from 'modules/v2/draft/utils/preview';
import { useTodoJourney, JourneyContextProvider } from 'modules/v2/context/JourneyContextProvider';
import { withView } from 'modules/v2/draft/containers';

import { EditorDropdown, Navbar } from './components';

const getSaveLabel = (saving, pending) => {
  if (saving) return 'Saving...';
  if (pending) return 'Save';

  return 'Saved';
};

const EditorTemplate = ({
  children,
  draftId,
  draftName,
  dropdown,
  goToDraftView,
  saveDraft,
  isSaving,
  book,
  preflight,
  links,
  isPendingSave,
  dropdownTitle,
  generateDigitalBook,
  getDraftPreview,
  getDraftLinks,
  goToEditDraftCover,
  goToEditDraftContent,
}) => {
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [insufficientCreditsModalOpen, setInsufficientCreditsModalOpen] = useState(false);
  const [welcomeJourneyFlow, setWelcomeJourneyFlow] = useState(false);
  const saveLabel = getSaveLabel(isSaving, isPendingSave);
  const modalTitle = `Book Title: ${draftName}`;
  const digitalBookStatus = get(links, ['data', 'status']);

  const isDigitalBookReady = isSucceeded(links.status) && isPreviewStatusReady(digitalBookStatus);
  const isNewDigitalBook = links.status === 'STATUS_INIT';
  const isDigitalBookModified = links?.data?.status === 'modified';
  const isDigitalBookLoading = isLoading(links.status) && !isPreviewStatusReady(digitalBookStatus);
  const isPublishButtonDisabled = saveLabel !== 'Saved' || isDigitalBookLoading;
  const bookTitle = get(book, ['data', 'name']);
  const history = useHistory();
  const location = useLocation();
  const { todos: fetchedTodos } = useTodoJourney();
  const { initializeTodos, tasks } = useTodos();

  useEffect(() => {
    initializeTodos(fetchedTodos);
  }, [fetchedTodos]);

  const customerPlanInfo = useCustomerPlanInfo();

  const { data: userBookCredits } = useQuery([fetchUserCredits], async () => {
    const response = await fetchUserCredits();
    return response?.data?.data?.credits;
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const { userData } = getData();
    if (queryParams.has('welcomeJourney') || userData?.welcomeJourneyEligible) {
      setWelcomeJourneyFlow(true);
    } else {
      setWelcomeJourneyFlow(false);
    }
  }, [location.search]);

  useEffect(() => {
    getDraftPreview(draftId);
    getDraftLinks(draftId, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDraftPreview(draftId);
    getDraftLinks(draftId, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  useEffect(() => {
    const isPreflightCheckFailed = isFailed(preflight.status);
    const isDigitalBookFailed = isFailed(links.status);

    if (isPreflightCheckFailed || isDigitalBookFailed) {
      setIsPublishModalOpen(false);
    }

    if (isDigitalBookReady) {
      setIsPublishModalOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preflight.status, links.status, isDigitalBookReady]);

  const handleExit = () => {
    const draftPath = getRouteDraftView(draftId);
    const url = welcomeJourneyFlow ? `${draftPath}?welcomeJourney` : draftPath;
    history.push(url);
  };

  const handlePublishDraft = async () => {
    setIsPublishModalOpen(true);
  };

  const handlePublishModalOpen = () => {
    generateDigitalBook(draftId);
  };

  const handlePublishModalClose = () => {
    setIsPublishModalOpen(false);
  };

  const handlePublishModalCancel = () => {
    setIsPublishModalOpen(false);
  };

  const handleNavigateToCheckout = () => {
    if (userBookCredits >= MINIMUM_CHECKOUT_BOOK_QUANTITY) {
      const draftPath = getRouteOrderCheckout(draftId);
      const url = welcomeJourneyFlow ? `${draftPath}?welcomeJourney` : draftPath;
      history.push(url);
      return false;
    }
    return setInsufficientCreditsModalOpen(true);
  };

  const handleNavigateToDraft = () => {
    const draftPath = getRouteDraftView(draftId);
    const url = welcomeJourneyFlow
      ? `${draftPath}?publishedBook&welcomeJourney`
      : `${draftPath}?publishedBook`;
    history.push(url);
  };

  const handleGenerationModalEditCover = () => {
    goToEditDraftCover(draftId);
    setIsPublishModalOpen(false);
  };

  const handleGenerationModalEditSection = (sectionId) => {
    goToEditDraftContent(draftId, sectionId);
    setIsPublishModalOpen(false);
  };

  const handleSaveDraft = async () => {
    await saveDraft();
  };

  return (
    <>
      <JourneyContextProvider>
        <Helmet>
          <style type="text/css">
            {`
            html {
              font-size: 16px;
              font-family: '"Figtree", sans-serif'
            }
          `}
          </style>
        </Helmet>
        <div className="grid h-screen grid-rows-[64px_calc(100vh-64px)]">
          <Navbar
            rightItems={[
              <div className="ml-4">
                <Button
                  type="dark"
                  size=""
                  onClick={handleExit}
                  className="text-[16px] pr-[17px] gap-[8px] font-semibold"
                >
                  <div className="mr-[8px]">
                    <ArrowLeft />
                  </div>
                  {bookTitle}
                </Button>
              </div>,
            ]}
            leftItems={[
              <div className="mr-2 h-[46px]">
                <EditorDropdown title={dropdownTitle} items={dropdown} />
              </div>,

              <div className="mr-2">
                {saveLabel === 'Save' ? (
                  <Button type="primary" onClick={handleSaveDraft} size="base" className="">
                    {saveLabel}
                  </Button>
                ) : (
                  <Button type="dark" size="base" className="">
                    <CheckIcon className="mr-2" />
                    {saveLabel}
                  </Button>
                )}
              </div>,

              <div className="">
                {links?.data?.status === 'ready' && !customerPlanInfo.isDigital ? (
                  <Button
                    color="primary"
                    size="base"
                    onClick={handleNavigateToCheckout}
                    disabled={
                      customerPlanInfo?.isTrialing &&
                      userBookCredits < MINIMUM_CHECKOUT_BOOK_QUANTITY
                    }
                  >
                    <OrderIcon className="mr-2" />
                    Order book
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    size="base"
                    onClick={handlePublishDraft}
                    isLoading={isDigitalBookLoading}
                    disabled={isPublishButtonDisabled || isDigitalBookReady}
                  >
                    <PublishIcon className="mr-2" />
                    {isDigitalBookLoading ? 'Publishing...' : null}
                    {isDigitalBookReady ? 'Published' : null}
                    {isDigitalBookModified ? 'Publish book' : null}
                    {isNewDigitalBook ? 'Publish book' : null}
                  </Button>
                )}
              </div>,
            ]}
          />

          <main className="flex">{children}</main>
        </div>
        <InsuficientCreditsModal
          showModal={insufficientCreditsModalOpen}
          setShowModal={setInsufficientCreditsModalOpen}
          credits={userBookCredits}
        />
        <GenerateDigitalBookModal
          isOpen={isPublishModalOpen}
          draftId={draftId}
          preflight={preflight}
          draftRoute={handleNavigateToDraft}
          isRouting
          status={isDigitalBookReady}
          title={modalTitle}
          onOpen={handlePublishModalOpen}
          onCancel={handlePublishModalCancel}
          onClose={handlePublishModalClose}
          onEditCover={handleGenerationModalEditCover}
          onEditSection={handleGenerationModalEditSection}
        />
      </JourneyContextProvider>
    </>
  );
};

const MemoCoverTemplate = React.memo(EditorTemplate);

const Composed = compose(
  withCover,
  withPreferences,
  withBook,
  withView,
  withDraft,
  withImageLibrary,
)(MemoCoverTemplate);

export default Composed;

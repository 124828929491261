import { getInstance } from './instance';

const instance = getInstance();

const getStats = (startDate, endDate) => {
  const params = { startDate, endDate };
  const url = 'analytics/book';
  return instance.get(url, { params });
};

const getAllLeads = () => {
  const url = 'email-campaigns/leads';

  return instance.get(url);
};

const getLead = (id) => {
  const url = `email-campaigns/leads/${id}`;

  return instance.get(url);
};

const getLeadsList = (perPage, page, sortBy, filters, onlyRMEligible, isRMDeliverable) => {
  const parsedSortBy = sortBy.replace(/\[|\]/g, '');
  const [sortKey, sortOrder] = parsedSortBy.split(':');

  const params = { perPage, page, ...{ [`sort[${sortKey}]`]: sortOrder } };
  if (onlyRMEligible) {
    params.onlyRMEligible = onlyRMEligible;
  }
  if (isRMDeliverable) {
    params.onlyRMDeliverable = isRMDeliverable;
  }
  const url = `email-campaigns/leads?${filters}`;

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const saveLeads = (body) => {
  const url = 'email-campaigns/leads';

  return instance.post(url, body);
};

const updateLeads = (body) => {
  const url = `email-campaigns/leads/${body?.id}`;

  return instance.patch(url, body);
};

const deleteLeads = (id) => {
  const url = `email-campaigns/leads/${id}`;

  return instance.delete(url);
};

// NOTE: Create new method for "delete all", so we don't break old API method.
const bulkLeadsDeleteAll = () => {
  const url = 'email-campaigns/leads/bulk-delete';
  return instance.post(url, { ids: [], deleteAll: true });
};

const bulkLeadsDelete = (leadIds) => {
  const url = 'email-campaigns/leads/bulk-delete';

  return instance.post(url, { ids: leadIds });
};

const importLeads = (request) => {
  const url = 'email-campaigns/leads/import-list';

  return instance.post(url, request);
};

const getEmailAnalytics = () => {
  const analytics = 'analytics/email-campaigns';
  return instance.get(analytics);
};

const getEmailSegments = () => {
  const url = 'email-campaigns/segments';
  return instance
    .get(url)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
};

const downloadLeads = () => {
  const downloadUrl = 'email-campaigns/leads/export-leads';
  return instance.post(downloadUrl, {
    responseType: 'blob',
  });
};

const unsubscribeLead = (id) => {
  const url = `email-campaigns/leads/${id}/unsubscribe`;
  return instance.get(url);
};

const updateDeliverableStatus = (body) => {
  const url = 'email-campaigns/leads/update/rm-deliverable';

  return instance.patch(url, body);
};

export {
  saveLeads,
  updateLeads,
  deleteLeads,
  getLeadsList,
  getEmailAnalytics,
  importLeads,
  downloadLeads,
  getEmailSegments,
  getAllLeads,
  getLead,
  getStats,
  unsubscribeLead,
  bulkLeadsDelete,
  bulkLeadsDeleteAll,
  updateDeliverableStatus,
};

const DeleteLeads = ({ count }) => {
  const countPhrase = count === 1 ? 'this lead' : `these ${count} leads`;

  return (
    <div>
      <p>Deleting {countPhrase} is permanent and cannot be undone.</p>
      <p>Are you sure you want to delete {countPhrase}?</p>
    </div>
  );
};

export default DeleteLeads;

import * as yup from 'yup';
import validation from 'modules/v2/common/validationSchema';

export const validationSchema = () =>
  yup.object().shape({
    currentStep: yup.number(),

    magazine: yup.object().when('currentStep', {
      is: 0,
      then: yup.object({
        _formFields: yup.object({
          agentName: yup.string().required('Type your agent name'),
          phoneNumber: validation.phoneNumber,
          email: validation.email,
          website: yup
            .string()
            .max(40, 'Only 40 characters are allowed.')
            .matches(
              /^(https?:\/\/)?(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/,
              'Please enter valid domain',
            ),
        }),
      }),
    }),
    frontInsideCover: yup.object().when('currentStep', {
      is: 2,
      then: yup.object({
        _formFields: yup.object({
          frontInsideCoverText: yup
            .string()
            .max(1500, 'Description must not be more than 1500 characters.'),
        }),
      }),
    }),

    backInsideCover: yup.object().when('currentStep', {
      is: 3,
      then: yup.object().when('selectedOption', (selectedOption) => {
        switch (selectedOption) {
          case 'backInsideCover-option-2':
            return yup.object({
              _formFields: yup.object({
                clientName1: yup.string().required('Enter Client Name 1'),
                testimonial1: yup.string().required('Enter Testimonial 1'),

                clientName2: yup.string().required('Enter Client Name 2'),
                testimonial2: yup.string().required('Enter Testimonial 2'),

                clientName3: yup.string().required('Enter Client Name 3'),
                testimonial3: yup.string().required('Enter Testimonial 3'),

                clientName4: yup.string(),
                testimonial4: yup.string(),

                clientName5: yup.string(),
                testimonial5: yup.string(),
              }),
            });

          case 'backInsideCover-option-3':
            return yup.object({
              _formFields: yup.object({
                listingAddress: yup.string().required('Enter Listing Address'),
                listingPrice: yup.string().required('Enter Listing Price'),
              }),
            });

          case 'backInsideCover-option-4':
            return yup.object({
              _formFields: yup.object({
                listingAddress: yup.string().required('Enter Listing Address'),
                listingPrice: yup.string().required('Enter Listing Price'),
                features: yup.string().required('Enter Features'),
              }),
            });

          case 'backInsideCover-option-6':
            return yup.object({
              _formFields: yup.object({
                partnerName: yup.string().required('Enter Partner Name'),
                partnerHeader: yup.string().required('Enter Partner Header'),
                partnerPhone: yup.string().required('Enter Partner Phone'),
                partnerEmail: yup.string().required('Enter Partner Email'),
                partnerAddress: yup.string().required('Enter Partner Address'),
                partnerDescription: yup.string().required('Enter Partner Description'),
              }),
            });

          default:
            return yup.object();
        }
      }),
    }),

    backCover: yup.object().when('currentStep', {
      is: 4,
      then: yup.object().when('selectedOption', (selectedOption) => {
        switch (selectedOption) {
          case 'backCover-option-3':
            return yup.object({
              _formFields: yup.object({
                bookLink: yup.string().required('Enter Book Link'),
              }),
            });

          case 'backCover-option-4':
            return yup.object({
              _formFields: yup.object({
                listingAddress: yup.string().required('Enter Listing Address'),
                listingPrice: yup.string().required('Enter Listing Price'),
              }),
            });

          case 'backCover-option-5':
            return yup.object({
              _formFields: yup.object({
                listingAddress: yup.string().required('Enter Listing Address'),
                description: yup.string().required('Enter Description'),
                features: yup.string().required('Enter Features'),
              }),
            });

          default:
            return yup.object();
        }
      }),
    }),
  });

export default validationSchema;

import getInstance from 'modules/api/instance';
import getAuthInstance from 'modules/api/instanceAuth';
import { getInstance as getDSInstance } from 'modules/api/digitalServices/instance';

const instance = getInstance();
const instanceAuth = getAuthInstance();
const dsInstance = getDSInstance();

export const fetchAutoSignin = () => {
  const url = '/auth/auto/login';

  return instance.get(url, { withCredentials: true });
};

export const getLoginAuth = (email) => {
  const url = '/users/exists';

  return instanceAuth
    .post(url, { email })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const updatePasswordFlag = (email, welcomeJourneyEligible, passwordChangeRequired) => {
  const url = 'users/update-user';

  const payload = {
    email,
    welcomeJourneyEligible,
    passwordChangeRequired
  }

  return instanceAuth
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const sendOtpRequest = ({ email, type }) => {
  const url = 'onboard/otp-validator';
  return dsInstance.post(url, { email, type });
};

export const loginViaOtp = ({ email, code }) => {
  const url = 'onboard/login-via-otp';
  return dsInstance
    .post(url, { email, code })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

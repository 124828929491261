import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { get, isEmpty } from 'lodash-es';
import { Tooltip } from 'modules/v2/common/AtomicDesign/molecules';

import { getRouteOrderCheckout } from 'modules/v2/draft/routes/navigation';
import { isFailed, isLoading, isSucceeded } from 'store/status';
import { fetchUserCredits } from 'modules/api';
import { getFlipbook } from 'modules/common/utils';
import { useCustomerPlanInfo } from 'modules/v2/utils';
import { getRouteEditDraftCover } from 'modules/v2/editor/routes/navigation';
import { withAuth } from 'modules/auth/containers';
import { withProfile } from 'modules/v2/containers';
import { Box, Button, VideoModal } from 'modules/v2/common/AtomicDesign/atoms';
import { InsuficientCreditsModal } from 'modules/v2/common/AtomicDesign/organisms';
import {
  AlertIcon,
  InfoIcon,
  InfoIconSlim,
  OrderIcon,
  PublishIcon,
} from 'modules/v2/common/components/SvgIcons';
import { useLocation } from 'react-router-dom';
import QuestionCircle from 'modules/v2/common/components/SvgIcon/QuestionCircle';
import { Modal, PrimaryBlackButton, SecondaryBlackButton } from 'modules/common/components';
import { DashboardTemplate } from 'modules/v2/templates';
import { withDraft, withPublish, withView } from 'modules/v2/draft/containers';
import { GenerateDigitalBookModal, Iframe, Link } from 'modules/v2/draft/components';
import { isPreviewStatusReady } from 'modules/v2/draft/utils/preview';
import { GenerateLinksView } from 'modules/v2/draft/components/GenerateDigitalBookModal/views';
import { ROUTE_WELCOME_JOURNEY } from 'modules/v2/routes/constants';
import { normalizeUrl } from 'modules/v2/utils/stringManipulation';
import { MINIMUM_CHECKOUT_BOOK_QUANTITY } from 'modules/v2/pages/constants';
import { CardWithVideo } from 'modules/v2/common/components';
import { ArrowRightTable } from 'modules/v2/common/components/SvgIcon';
import { getRouteHomePage } from 'modules/v2/routes/navigation';
import useTodos from 'modules/v2/context/JourneyContextReducer';
import { JourneyContextProvider, useTodoJourney } from 'modules/v2/context/JourneyContextProvider';
import {
  editBookVideoSource,
  thumbnailSource,
  TASK_NAME,
  BOOK_SETUP_STEPS,
  TASK_STATUS,
  SETUPBOOK_TASKS_CHECK
} from 'modules/v2/pages/WelcomeJourney/utils/constants';
import { setItem } from 'modules/dashboard/utils/legacy';
import { getData } from 'modules/auth';
import { generateClickUrl } from 'modules/v2/pages/WelcomeJourney/utils/NavigationHandler';


import S from './styles';

const View = ({
  draftId,
  draftName,
  draftUpdatedAt,
  links,
  preflight,
  preview,
  history,
  clearDigitalBook,
  generateDigitalBook,
  generateDraftPreview,
  getDraftPreview,
  getDraftLinks,
  goToEditDraftCover,
  goToEditDraftContent,
}) => {
  const [isGenerationModalOpen, setIsGenerationModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [userBookCredits, setUserBookCredits] = useState(null);
  const [insufficientCreditsModalOpen, setInsufficientCreditsModalOpen] = useState(false);
  const [welcomeJourneyFlow, setWelcomeJourneyFlow] = useState(false);
  const [turnOffButtons, setTurnOffButtons] = useState(false);
  const [showModalCardVideo, setShowModalCardVideo] = useState(false);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isDraftRouting, setIsDraftRouting] = useState(false);

  const digitalBookStatus = get(links, ['data', 'status']);
  const isDigitalBookReady = isSucceeded(links.status) && isPreviewStatusReady(digitalBookStatus);
  const isNewDigitalBook = links.status === 'STATUS_INIT';
  const isDigitalBookModified = links?.data?.status === 'modified' && !isNewDigitalBook;

  const isPreviewLoading = isLoading(preview.status);
  const isPreviewFailed = isFailed(preview.status);
  const previewUrl = get(preview, ['data', 'url']);
  const previewLastModified = get(preview, ['data', 'lastModified']);
  const flipbookUrl = getFlipbook(previewUrl, previewLastModified);

  const landingPageUrl = get(links, ['data', 'url', 'site'], '');
  const digitalBookUrl = get(links, ['data', 'url', 'book'], '');

  const [showPublishedBookModal, setShowPublishedBookModal] = useState(false);
  const location = useLocation();
  const { todos: fetchedTodos } = useTodoJourney();
  const { initializeTodos, tasks } = useTodos();

  useEffect(() => {
    initializeTodos(fetchedTodos);
  }, [fetchedTodos]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const hasPublishedBook = queryParams.has('publishedBook');
    const hasWelcomeJourney = queryParams.has('welcomeJourney');
    const hasTurnOffButtons = queryParams.has('turnoff');
    if(hasWelcomeJourney && !isEmpty(fetchedTodos)){
      const getCompletedTasks = fetchedTodos.filter(
        (item) =>
          SETUPBOOK_TASKS_CHECK.includes(item.name) && item.status === TASK_STATUS.COMPLETED,
      );
      const setUpBook = fetchedTodos.find(item => item.name === TASK_NAME.SETUPBOOK);
      const orderCompleted = setUpBook?.steps?.find(item => item.name === BOOK_SETUP_STEPS.ORDERBOOKSTEP && item.isCompleted);
      if (getCompletedTasks.length === 2) {
        setIsLastStep(true);
      }
      if(!isEmpty(orderCompleted)){
        setIsDraftRouting(true);
      }
    }
    setShowPublishedBookModal(hasPublishedBook);
    setWelcomeJourneyFlow(hasWelcomeJourney);
    setTurnOffButtons(hasTurnOffButtons);
  }, [location.search, fetchedTodos]);

  const customerPlanInfo = useCustomerPlanInfo();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchUserCredits();
        setUserBookCredits(response?.data?.data?.credits);
      } catch (error) {
        console.error('Error fetching user credits:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    getDraftPreview(draftId);
    getDraftLinks(draftId, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isPreflightCheckFailed = isFailed(preflight.status);
    const isDigitalBookFailed = isFailed(links.status);

    if (isPreflightCheckFailed || isDigitalBookFailed) {
      setIsGenerationModalOpen(false);
      clearDigitalBook();
    }

    if (isDigitalBookReady) {
      setIsGenerationModalOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preflight.status, links.status, isDigitalBookReady]);

  const handleNavigateToDraft = () => {
    if(isDraftRouting){
      if(isLastStep){
        const { userData } = getData();
        const updatedUser = {
          ...userData,
          welcomeJourneyEligible: false,
        };
        setItem('userData', updatedUser);
        const path = getRouteHomePage();
        history.push(`${path}?welcomeJourneyCompleted`);
      } else {
        const path = generateClickUrl('');
        history.push(`${path}&completed=setupBookJourney`);
      }
    }
  };

  const handleGenerateDigitalBookClick = async () => {
    setIsGenerationModalOpen(true);
  };

  const handleGenerationModalOpen = () => {
    generateDigitalBook(draftId);
  };

  const handleGenerationModalClose = () => {
    setIsGenerationModalOpen(false);
  };

  const handleGenerationModalCancel = () => {
    setIsGenerationModalOpen(false);
    //  Todo: work in progress
    // cancelGenerateDigitalBook();
  };

  const handleGenerationModalEditCover = () => {
    goToEditDraftCover(draftId);
  };

  const handleGenerationModalEditSection = (sectionId) => {
    goToEditDraftContent(draftId, sectionId);
  };

  const handleRetry = () => {
    generateDraftPreview(draftId);
  };

  const handleClickEditBook = () => {
    const draftPath = getRouteEditDraftCover(draftId);
    const url = welcomeJourneyFlow ? `${draftPath}?welcomeJourney` : draftPath;
    window.location.assign(url);
  };

  const getStatus = (status) => {
    if (isDigitalBookModified) {
      return (
        <span className="text-[#D9630B] bg-[#FFE9D9] px-2 py-1 rounded-md text-sm font-medium">
          Editing
        </span>
      );
    }

    if (isLoading(status)) {
      return (
        <span className="text-primary-500 bg-primary-50 px-2 py-1 rounded-md text-sm font-medium">
          Publishing...
        </span>
      );
    }

    if (isDigitalBookReady) {
      return (
        <span className="text-success-500 bg-success-50 px-2 py-1 rounded-md text-sm font-medium">
          Published
        </span>
      );
    }

    return null;
  };

  const getLastEditedFormat = (date) => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  const handleNavigateToCheckout = () => {
    if (userBookCredits >= MINIMUM_CHECKOUT_BOOK_QUANTITY) {
      const draftPath = getRouteOrderCheckout(draftId);
      const url = welcomeJourneyFlow ? `${draftPath}?welcomeJourney` : draftPath;
      history.push(url);
      return false;
    }
    return setInsufficientCreditsModalOpen(true);
  };

  const handleShowOrderBookButton = () => {
    return links?.data?.status === 'ready';
  };

  return (
    <JourneyContextProvider>
      <DashboardTemplate>
        {welcomeJourneyFlow && (
          <div>
            <div
              className="text-sm font-normal text-neutral-700 gap-0 bg-neutral-50 md:max-w-[700px] lg:max-w-[1408px] mx-auto flex items-center cursor-pointer"
              onClick={() => history.push(ROUTE_WELCOME_JOURNEY)}
            >
              <ArrowRightTable className="realtive transform rotate-180 w-4 h-4 flex items-center justify-center" />
              <span className="px-2">Your action steps</span>
            </div>

            <div className="flex flex-col items-center justify-center py-8 gap-8 bg-neutral-50">
              <VideoModal
                title="Discover How to Edit Your Book"
                showModal={showModalCardVideo}
                setShowModal={setShowModalCardVideo}
                videoSource={editBookVideoSource}
                icon={<ArrowRightTable width={20} height={20} />}
                buttonText="Start editing my book"
                // onClick={VideoModalButtonClick}
              />

              <CardWithVideo
                imageSource={thumbnailSource}
                title="How to Customize Your Book"
                content="Watch our video to learn how to edit your book. It shows quick edits to match your brand. You can choose your cover, add your photo, customize your bio, or include your broker logos."
                iconClick={() => setShowModalCardVideo(!showModalCardVideo)}
              />
            </div>
          </div>
        )}

        <div className="flex justify-between items-center mb-4 flex-wrap">
          <h3 className="2xl font-extrabold text-neutral-800 lowercase first-letter:capitalize">
            {draftName}
          </h3>

          {!turnOffButtons &&
            (handleShowOrderBookButton() ? (
              <Button
                color="primary"
                size="base"
                onClick={handleNavigateToCheckout}
                disabled={
                  turnOffButtons ||
                  (customerPlanInfo?.isTrialing && userBookCredits < MINIMUM_CHECKOUT_BOOK_QUANTITY)
                }
              >
                <OrderIcon className="mr-2" />
                Order book
              </Button>
            ) : (
              <Button
                color="primary"
                size="base"
                disabled={isLoading(links.status) || links?.data?.status === 'ready'}
                onClick={handleGenerateDigitalBookClick}
              >
                <PublishIcon className="mr-2" />
                {isLoading(links.status) ? 'Publishing...' : null}
                {links?.data?.status === 'ready' ? 'Published' : null}
                {links?.data?.status === 'modified' ? 'Publish book' : null}
              </Button>
            ))}
        </div>
        {!isDigitalBookReady && (
          <div className="w-full h-full px-3 py-2.5 bg-orange-100 rounded-md justify-start items-start gap-3 mb-6 inline-flex">
            <AlertIcon fill="#d9630b" stroke="#d9630b" />
            <p className="text-[#D9630B] text-sm font-medium leading-tight">
              If everything looks good, click “Publish” to update your website links, and to order
              the latest copy.
            </p>
          </div>
        )}

        {isDigitalBookReady && (
          <div className="w-full h-10 px-3 py-2.5 bg-neutral-100 rounded-md justify-start items-center gap-3 mb-6 inline-flex">
            <InfoIconSlim />
            <p className="text-neutral-700 text-sm font-semibold leading-tight">
              To order books, click on "Order book" to continue with the order flow.
            </p>
          </div>
        )}

        <div className="flex gap-6 max-[1280px]:flex-col">
          <Box className="p-6 w-full min-w-[340px] min-h-[545px]">
            <div className="mb-4 flex justify-between">
              <span className="h-[46px] flex items-center text-base">Your book</span>
              {!turnOffButtons && (
                <Button color="outlined" size="base" onClick={handleClickEditBook}>
                  Edit book
                </Button>
              )}
            </div>
            <div className="h-[437px] overflow-hidden">
              <Iframe
                isLoading={isPreviewLoading}
                hasError={isPreviewFailed}
                height="437px"
                src={flipbookUrl}
                onRetry={handleRetry}
              />
            </div>
          </Box>

          <div className="flex-col w-[389px] max-[1280px]:w-full">
            <Box className="mb-6 min-w-[340px] max-w-[390px] max-[1280px]:max-w-full">
              <div className="flex justify-between border-b-[1px] border-neutral-200 items-center mx-6 py-[14px] mb-[14px]">
                <div className="text-boxTitle">Status</div>
                {getStatus(links.status)}
              </div>
              <div className="flex justify-between items-center mx-6 py-[14px] mb-[14px]">
                <div className="text-boxTitle">Last edited</div>
                <span className="text-neutral-800 text-sm font-medium">
                  {getLastEditedFormat(draftUpdatedAt)}
                </span>
              </div>
            </Box>

            {!isEmpty(landingPageUrl) && !isEmpty(digitalBookUrl) && (
              <Box className="min-w-[340px] max-w-[390px] p-6 max-[1280px]:max-w-full">
                {(!isDigitalBookReady || isDigitalBookModified) && (
                  <div className="bg-neutral-100 rounded-md w-full pl-[44px] pr-3 py-2.5 mb-4 relative">
                    <InfoIcon fill="#252525" className="top-3 left-3 absolute" />
                    <p className="text-sm font-semibold">
                      Your website is not up to date. Publish your book to update your lead capture
                      website and digital book.
                    </p>
                  </div>
                )}
                <div className="w-full">
                  <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                    Lead capture website
                    <Tooltip
                      content="Easily showcase your expertise and build trust with clients who are unable to meet with you in person."
                      Style="dark"
                      className="max-w-[250px] text-sm text-neutral-200"
                    >
                      <QuestionCircle />
                    </Tooltip>
                  </h5>
                  <Link key="landingPage" id="landingPage" href={normalizeUrl(landingPageUrl)} />
                </div>
                <div className="w-full mt-6">
                  <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                    Digital book
                    <Tooltip
                      content="Your digital book"
                      Style="dark"
                      className="max-w-[250px] text-sm text-neutral-200 ml-2"
                    >
                      <QuestionCircle />
                    </Tooltip>
                  </h5>
                  <Link key="digitalBook" id="digitalBook" href={normalizeUrl(digitalBookUrl)} />
                </div>
              </Box>
            )}
          </div>
        </div>

        <GenerateDigitalBookModal
          isOpen={isGenerationModalOpen}
          draftId={draftId}
          preflight={preflight}
          status={isDigitalBookReady}
          draftRoute={handleNavigateToDraft}
          isRouting={isDraftRouting}
          publishRoute={handleNavigateToCheckout}
          onOpen={handleGenerationModalOpen}
          onCancel={handleGenerationModalCancel}
          onClose={handleGenerationModalClose}
          onEditCover={handleGenerationModalEditCover}
          onEditSection={handleGenerationModalEditSection}
        />

        {showPublishedBookModal && (
          <GenerateLinksView
            publishRoute={handleNavigateToCheckout}
            openModal={showPublishedBookModal}
            onCancel={() => setShowPublishedBookModal(false)}
          />
        )}

        {isWarningModalOpen && (
          <Modal
            isOpen={isWarningModalOpen}
            maskClosable
            title="Warning"
            width="auto"
            footer={[
              <S.ModalFooterContainer key={1}>
                <PrimaryBlackButton style={{ width: '80%' }} disabled>
                  Buy Credits
                </PrimaryBlackButton>
                <SecondaryBlackButton
                  style={{ width: '80%' }}
                  onClick={() => {
                    setIsWarningModalOpen(false);
                  }}
                >
                  Cancel, I'll buy later
                </SecondaryBlackButton>
              </S.ModalFooterContainer>,
            ]}
          >
            <S.ContentContainer>
              <S.ContentTitle>
                You have <span>{userBookCredits} credits</span> left
              </S.ContentTitle>
              <S.ContentDescription>1 Credit = 1 Printed Book</S.ContentDescription>
              <S.ContentDescription>
                Please check with support to learn more about Authorify plans.
              </S.ContentDescription>
            </S.ContentContainer>
          </Modal>
        )}
        <InsuficientCreditsModal
          showModal={insufficientCreditsModalOpen}
          setShowModal={setInsufficientCreditsModalOpen}
          credits={userBookCredits}
        />
      </DashboardTemplate>
    </JourneyContextProvider>
  );
};

export default compose(withAuth, withProfile, withDraft, withPublish, withView)(View);

import * as color from 'modules/v2/common/theme/color';
import coverOption1 from '../../public/cover1.jpg';
import coverStripOption1 from '../../public/Option-1-Cover-Strip.png';
import backCoverOption1 from '../../public/Back-Cover-Option-1.jpg';

// mock data for steps

export const frontCoverOptionsList = [
  {
    id: 1,
    name: 'Option 1',
    image: coverOption1,
  },
  {
    id: 2,
    name: 'Option 1',
    image: coverOption1,
  },
  {
    id: 3,
    name: 'Option 1',
    image: coverOption1,
  },
  {
    id: 4,
    name: 'Option 1',
    image: coverOption1,
  },
  {
    id: 5,
    name: 'Option 1',
    image: coverOption1,
  },
];

export const frontCoverStripOptionsList = [
  {
    id: 1,
    name: 'Basic contact info',
    image: coverStripOption1,
  },
  {
    id: 2,
    name: 'Brokerage logo',
    image: coverStripOption1,
  },
  {
    id: 3,
    name: 'Your social media',
    image: coverStripOption1,
    additionaInfo: 'SOCIAL_MEDIA_FORM',
  },
  {
    id: 4,
    name: 'QR code to subscription page',
    image: coverStripOption1,
    additionaInfo: 'QR-CODE',
  },
];

export const backCoverOptionsList = [
  {
    id: 1,
    name: 'Basic contact info',
    image: backCoverOption1,
  },
  {
    id: 2,
    name: 'Selling home with QR code',
    image: backCoverOption1,
    additionaInfo: 'QR-CODE',
  },
  {
    id: 3,
    name: 'Book Ad (for Authorify members only)',
    image: backCoverOption1,
    additionaInfo: 'INPUT',
  },
  {
    id: 4,
    name: 'Just sold!',
    image: backCoverOption1,
    additionaInfo: 'LIST-DETAILS',
  },
  {
    id: 5,
    name: 'Just listed!',
    image: backCoverOption1,
    additionaInfo: 'LIST-DETAILS-WITH-TEXTAREA',
  },
  {
    id: 6,
    name: 'What is your Home Truly Worth?',
    image: backCoverOption1,
  },
];

export const frontCoverStripOptionsHeading = {
  'frontCoverStrip-option-1': '',
  'frontCoverStrip-option-2': 'Your brokerage',
  'frontCoverStrip-option-3': 'Your social media',
  'frontCoverStrip-option-4': 'QR code to a subscription page',
};

export const backCoverStripOptionsHeading = {
  'backCover-option-1': '',
  'backCover-option-2': 'QR code to a subscription page',
  'backCover-option-3': 'Book Ad (for Authorify members only)',
  'backCover-option-4': 'Just sold!',
  'backCover-option-5': 'Just listed!',
  'backCover-option-6': '',
};

export const backInsideCoverOptionsHeading = {
  'backInsideCover-option-1': '',
  'backInsideCover-option-2': 'Add testimonials (maximum of 5 only)',
  'backInsideCover-option-3': 'Just sold!',
  'backInsideCover-option-4': 'Just listed!',
  'backInsideCover-option-5': '',
  'backInsideCover-option-6': 'Referral partner',
};

export const frontCoverStripOptionFormFields = {
  'frontCoverStrip-option-1': null,
  'frontCoverStrip-option-2': {
    listingImage: '',
  },
  'frontCoverStrip-option-3': {
    facebookUsername: '',
    twitterUsername: '',
    linkedInUsername: '',
    instagramUsername: '',
  },
  'frontCoverStrip-option-4': {
    qrCode: '',
  },
};

export const magazineFormFields = {
  agentName: '',
  brokerName: '',
  phoneNumber: '',
  email: '',
  website: '',
  address: '',
  profileImage: '',
  brokerageLogo: '',
};

export const backInsideCoverOptionsFormFields = {
  'backInsideCover-option-1': null,
  'backInsideCover-option-2': {
    clientName1: '',
    clientName2: '',
    clientName3: '',
    clientName4: '',
    clientName5: '',
    testimonial1: '',
    testimonial2: '',
    testimonial3: '',
    testimonial4: '',
    testimonial5: '',
  },
  'backInsideCover-option-3': {
    listingImageJustSold: '',
    listingAddress: '',
    listingPrice: '',
  },
  'backInsideCover-option-4': {
    listingImageJustListed: '',
    listingAddress: '',
    listingName: '',
    description: '',
    features: '',
  },
  'backInsideCover-option-5': null,
  'backInsideCover-option-6': {
    partnerImage: '',
    partnerName: '',
    partnerHeader: '',
    partnerAddress: '',
    partnerPhone: '',
    partnerDescription: '',
    partnerEmail: '',
  },
};

export const backCoverStripOptionsFormFields = {
  'backCover-option-1': null,
  'backCover-option-2': {
    qrCode: '',
  },
  'backCover-option-3': {
    bookLink: '',
  },
  'backCover-option-4': {
    listingImageJustSold: '',
    listingAddress: '',
    listingPrice: '',
  },
  'backCover-option-5': {
    listingImageJustListed: '',
    listingAddress: '',
    description: '',
    features: '',
  },
  'backCover-option-6': null,
};

export const nextStepButtonStyle = {
  marginLeft: '10px',
};

export const inputErrorStyles = {
  borderColor: color.ErrorWarning,
};

import { getInstance } from './instance';

const instance = getInstance();

export const getMagazineStepsInfo = ({ queryKey }) => {
  const [, month, year] = queryKey;
  return instance
    .get(`referral-marketing/magazines/pages?month=${month}&year=${year}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const createReferralMagazine = (req) => {
  return instance
    .post('/referral-marketing/magazines', req.payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const updateReferralMagazine = (req) => {
  const { month, year, ...rest } = req;

  return instance
    .patch(`/referral-marketing/magazines/${year}/${month}`, rest?.payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getGeneratedMagazine = (year, month) => {
  return instance
    .get(`/referral-marketing/generated-magazines/${year}/${month}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getGeneratedMagazineMonth = ({ queryKey }) => {
  const [, year, month] = queryKey;
  let params = '';
  if (month && year) {
    params = `/${year}/${month}`;
  }
  return instance
    .get(`/referral-marketing/generated-magazines${params}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getGeneratedMagazineYear = (year, status) => {
  return instance
    .get(`/referral-marketing/magazines?year=${year}&status=${status}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getMagazine = (year, month) => {
  return instance
    .get(`/referral-marketing/magazines/${year}/${month}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const generateReferralMagazine = ({ isPreview = true, payload }) => {
  return instance
    .post(`/referral-marketing/generated-magazines?isPreview=${isPreview}`, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const updateGeneratedReferralMagazine = ({ params, payload }) => {
  const { month, year } = params;
  return instance
    .patch(`/referral-marketing/generated-magazines/${year}/${month}`, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const updateGeneratedMagazineById = ({ id, payload }) => {
  return instance
    .patch(`/referral-marketing/generated-magazines/${id}`, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const sendToPrint = ({ id }) => {
  return instance
    .post(`/referral-marketing/generated-magazines/${id}/print`, {})
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const sendBackToEdit = ({ id, status }) => {
  const url = `/referral-marketing/magazines/update-status/${id}`;
  const body = { status };
  return instance
    .patch(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getGeneratedMagazineById = (id) => {
  return instance
    .get(`/referral-marketing/generated-magazines/generatedMagazine/status/${id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { get, isEmpty } from 'lodash-es';
import { useQuery } from 'react-query';

import { withProfile } from 'modules/v2/containers';
import { withAuth } from 'modules/auth/containers';
import { DashboardNavBar } from 'modules/v2/components';
import { SidebarMenu } from 'modules/v2/common/components';
import { getActiveSubscriptions } from 'modules/api';
import { notification } from 'modules/v2/common/utils';
import { getData } from 'modules/auth';
import { fetchJourneyTodos } from 'modules/api/common';

import './styles.css';
import RelativeLoading from 'v2/common/AtomicDesign/RelativeLoading';
import { cn } from 'modules/v2/common/utils/cn';
import env from 'environments';
import { TaskStatusTypes } from './types';
import { TRIAL_EXPIRING_DAYS } from './utils';

const DashboardTemplate = ({
  avatar,
  children,
  title,
  subHeader,
  profile,
  signOut,
  isLoading,
  isError,
  hasNoData,
  navBar,
}) => {
  const { email, firstName } = get(profile, 'data', {});
  const showContent = !isLoading && !hasNoData && !isError;

  const isStringTitle = typeof title === 'string';
  const isReactElementSubHeader = React.isValidElement(subHeader);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [openMobile, setOpenMobile] = useState(false);
  const [welcomeJourneyEligible, setWelcomeJourneyEligible] = useState(false);
  const [hasTrial, setHasTrial] = useState(false);
  const [remainingDays, setRemainingDays] = useState('');
  const [taskCount, setTaskCount] = useState(0);
  const [completedTaskCount, setCompletedTaskCount] = useState(3);

  const { data: subscription } = useQuery(['getSubscription'], getActiveSubscriptions);

  const { data: journeyTodos, isLoading: isWjLoading } = useQuery(
    'fetchJourneyTodos',
    fetchJourneyTodos,
    {
      enabled: welcomeJourneyEligible,
    },
  );

  useEffect(() => {
    const { userData } = getData();
    if (userData?.welcomeJourneyEligible) {
      setWelcomeJourneyEligible(true);
      if (journeyTodos && !isWjLoading) {
        const { data } = journeyTodos;
        setTaskCount(data.length - 1);
        const result = data.reduce(
          (acc, todo) => {
            if (todo?.status === TaskStatusTypes.COMPLETED) {
              acc.completedCount += 1;
            }
            return acc;
          },
          { completedCount: -1 },
        );
        setCompletedTaskCount(result.completedCount);
      }
    } else {
      setWelcomeJourneyEligible(false);
    }
  }, [welcomeJourneyEligible, isWjLoading, journeyTodos, completedTaskCount]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(subscription)) {
      const subscriptionStatus = get(subscription, 'state');
      if (subscriptionStatus === 'trialing') {
        setHasTrial(true);
        const renewalDate = new Date(subscription?.current_period_ends_at);
        const today = new Date();
        const differenceInTime = renewalDate.getTime() - today.getTime();
        setRemainingDays(Math.ceil(differenceInTime / (1000 * 3600 * 24)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  const handleOpenMobile = () => {
    setOpenMobile(!openMobile);
  };

  const notificationMessage = `🕜 You have ${
    remainingDays > TRIAL_EXPIRING_DAYS ? TRIAL_EXPIRING_DAYS : remainingDays
  } days left to your trial`;

  const progressContent = (
    <div
      className={cn('flex flex-row fixed w-full z-[999] items-start justify-start top-[62px]', {
        'top-[108px]': hasTrial,
      })}
    >
      <div
        className={cn(
          'h-1 mr-1 bg-neutral-300 w-[calc(33.3%-1px)]',
          { 'bg-yellow-300': completedTaskCount > 0 },
          { 'w-[calc(33.1%-1px)]': taskCount === 3 && windowSize < 1024 },
          { 'w-[50%]': taskCount === 2 },
        )}
      />
      <div
        className={cn(
          'h-1 mr-1 bg-neutral-300 w-[calc(33%-1px)]',
          { 'bg-yellow-300': completedTaskCount > 1 },
          { 'w-[calc(32.4%-1px)]': taskCount === 3 && windowSize < 1024 },
          { 'w-[48%]': taskCount === 2 },
        )}
      />
      <div
        className={cn(
          'h-1 bg-neutral-300 w-[calc(33%-1px)]',
          { 'bg-yellow-300': completedTaskCount > 2 },
          { 'w-[calc(32.9%-1px)]': taskCount === 3 && windowSize < 1024 },
        )}
      />
    </div>
  );

  return (
    <>
      <Helmet>
        <style type="text/css">
          {`
            html {
              font-size: 16px;
            }

            html #root {
              font-family: "Figtree", sans-serif!important
            }
          `}
        </style>
      </Helmet>
      {windowSize > 1024 ? (
        <div className="tracking-normal">
          {navBar || (
            <DashboardNavBar
              email={email}
              avatar={avatar}
              username={firstName}
              onLogout={signOut}
              welcomeJourneyEligible={welcomeJourneyEligible}
            />
          )}

          {hasTrial && (
            <div className="fixed font-medium top-[62px] bg-neutral-800 w-full text-white h-[46px] z-[998] flex items-center justify-center text-sm">
              {notificationMessage}
            </div>
          )}

          {welcomeJourneyEligible && (
            <div>{(taskCount === 2 || taskCount === 3) && progressContent}</div>
          )}

          <aside
            id="sidebar-multi-level-sidebar"
            className={cn(
              'fixed top-[58px] mt-[10px] left-0 z-40 w-sidebar h-screen transition-transform -translate-x-full sm:translate-x-0 text-xs',
              { 'top-[95px] ': hasTrial },
            )}
            aria-label="Sidebar"
          >
            <SidebarMenu />
          </aside>
          <div
            className={cn(
              'relative left-[268px] top-[54px] w-[calc(100%-271px)] min-h-[calc(100vh-62px)] bg-neutral-50 p-[34px] max-sm:w-full max-sm:h-full max-sm:left-0 max-sm:px-8 max-sm:ml-0',
              { 'top-[95px] ': hasTrial },
            )}
          >
            <RelativeLoading isLoading={isLoading} className="bg-opacity-60" />
            <div className="w-full h-full mr-auto">
              {isStringTitle && <div className="text-2xl font-bold flex-1 mb-6">{title}</div>}
              {isReactElementSubHeader && subHeader}
              {showContent && <div className="w-full h-fit">{children}</div>}
            </div>
          </div>
        </div>
      ) : (
        <div className="relative">
          {navBar || (
            <DashboardNavBar
              email={email}
              avatar={avatar}
              username={firstName}
              onLogout={signOut}
              mobileDevice={handleOpenMobile}
              welcomeJourneyEligible={welcomeJourneyEligible}
            />
          )}

          {hasTrial && (
            <div className="fixed font-medium top-[62px] bg-neutral-800 w-full text-white h-[46px] z-[999] flex items-center justify-center text-sm">
              {notificationMessage}
            </div>
          )}

          {welcomeJourneyEligible && (
            <div>{(taskCount === 2 || taskCount === 3) && progressContent}</div>
          )}
          {openMobile ? <SidebarMenu hasTrial={hasTrial} /> : null}
          <div
            className={cn('relative top-[52px] w-full h-full bg-neutral-50 p-6', {
              'top-[99px] ': hasTrial,
            })}
          >
            {isStringTitle && <div className="text-2xl font-bold flex-1 mb-6">{title}</div>}
            {isReactElementSubHeader && subHeader}
            {showContent && <div className="w-full h-fit">{children}</div>}
          </div>
        </div>
      )}
    </>
  );
};

export default compose(withProfile, withAuth)(DashboardTemplate);

import { useState } from 'react';
import { useQuery } from 'react-query';
import { map, get } from 'lodash-es';

import { Alert } from 'flowbite-react';
import { Loader } from 'modules/v2/common/components';
import { getGeneratedMagazineYear } from 'modules/api';
import sampleCoverImage from 'modules/v2/pages/ReferralMarketingMagazines/public/cover1.jpg';
import { currentYear } from 'modules/v2/common/utils/date';
import { MAGAZINE_STATUS } from 'modules/v2/pages/admin/RmMagazineReport/enum';
import CommonStyles from '../../styles';
import PreviousMagazineDetails from '../PreviousMagazineDetails';
import { monthsList } from '../../constants';

const PreviousMagazine = () => {
  const [selectedPreviousMagazine, setSelectedPreviousMagazine] = useState('');
  const { isFetching: loadingPreviousMagazines, data: previousMagazines = {} } = useQuery(
    ['getGeneratedMagazineYear', currentYear, MAGAZINE_STATUS.SENT_FOR_PRINTING],
    () => getGeneratedMagazineYear(currentYear, MAGAZINE_STATUS.SENT_FOR_PRINTING),
  );
  const onBack = () => setSelectedPreviousMagazine('');

  if (loadingPreviousMagazines) {
    return (
      <Loader
        height="75vh"
        title="Fetching Magazines"
        hasIcon={false}
        description="Please wait while we fetching previous magazines."
      />
    );
  }

  if (selectedPreviousMagazine) {
    return <PreviousMagazineDetails selectedMagazine={selectedPreviousMagazine} onBack={onBack} />;
  }

  const filterMagazinesWithNoMagazineID = previousMagazines.filter((prev) => prev?.id);

  if (!filterMagazinesWithNoMagazineID?.length) {
    return (
      <div className="relative w-full h-full min-h-[630px] overflow-hidden">
        <div className="w-full h-full flex flex-col justify-center bg-white rounded-lg p-6 shadow-box">
          <div className="frame_280 flex flex-col justify-center items-center self-stretch gap-4">
            <div className="frame_824 flex flex-col justify-center items-center self-stretch">
              <div className="frame_191 flex flex-col items-center self-stretch">
                <Alert className="self-stretch text-neutral-800 text-center  text-base font-semibold">
                  No previous magazine
                </Alert>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <CommonStyles.SubPageContainer width="100%">
        <div>
          <div className="text-neutral-800 font-extrabold leading-7 text-2xl mb-6">
            Previous Magazine
          </div>
        </div>
        <div className="text-boxTitle text-base font-semibold py-[22px] px-6 bg-white shadow-box rounded-t-lg border-b border-neutral-200">
          Your past magazines within the 12 months period
        </div>
        <div className="w-full flex flex-col gap-6 flex-wrap overflow-x-auto items-start gap-10 bg-white shadow-box rounded-b-lg self-stretch p-6">
          <div className="flex gap-[50px]">
            {map(filterMagazinesWithNoMagazineID, (magazine) => {
              const imageUrl =
                magazine.image && magazine.image.startsWith('https://')
                  ? magazine.image
                  : sampleCoverImage;
              return (
                <div className="flex flex-col gap-[12px]">
                  <div
                    onClick={() => setSelectedPreviousMagazine(magazine)}
                    className="w-[210px] h-[324px] border border-neutral-300 rounded-md cursor-pointer overflow-hidden"
                  >
                    <img src={imageUrl} alt="" className="h-full w-auto" />
                  </div>
                  <div className="flex flex-col flex-wrap gap-1">
                    <div className="w-auto text-neutral-400 text-sm font-normal capitalize">
                      {`${monthsList[get(magazine, 'month')]} ${get(magazine, 'year')}`}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </CommonStyles.SubPageContainer>
    </>
  );
};

export default PreviousMagazine;

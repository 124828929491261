import { Form, useFormikContext } from 'formik';
import { get, map } from 'lodash-es';
import BaseInputMask from 'react-input-mask';

import { useSelector } from 'react-redux';
import { SelectMultiField, SelectField } from 'modules/v2/common/components';
import { TextInput, SwitchButton } from 'modules/v2/common/AtomicDesign/atoms';
import { PinIcon } from 'modules/v2/common/components/SvgIcon';
import { LabelInput } from 'modules/v2/common/AtomicDesign/atoms/';

import S from './style';

const EditLeads = ({ countries, states, segmentsData }) => {
  const { isRm: showRmFields, isBooks } = useSelector((data) => data.bundleAccess);
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } = useFormikContext();

  const optionBuilder = (item) => ({ title: item, value: item });

  const selectedCountry = values?.country;
  const selectedStateList = get(states, selectedCountry);
  const countryList = map(countries, optionBuilder);
  const stateList = map(selectedStateList, optionBuilder);
  const handleCountryChange = () => setFieldValue('state', undefined);
  const setIsRm = () => {
    setFieldValue('isRm', !values.isRm);
  };

  return (
    <div className="h-fit">
      <div className="mb-6">
        <Form>
          <div className="flex gap-3.5 mb-3.5">
            <S.FieldControl>
              <LabelInput required>First name</LabelInput>
              <TextInput
                id="firstName"
                name="firstName"
                value={values?.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.firstName && touched.firstName ? (
                <span className="text-error-500 mt-2">{errors.firstName}</span>
              ) : null}
            </S.FieldControl>
            <S.FieldControl>
              <LabelInput required>Last name</LabelInput>
              <TextInput
                id="lastName"
                name="lastName"
                value={values?.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.lastName && touched.lastName ? (
                <span className="text-error-500 mt-2">{errors.lastName}</span>
              ) : null}
            </S.FieldControl>
          </div>
          <div className="flex gap-3.5 mb-3.5">
            <S.FieldControl>
              <LabelInput required>Email address</LabelInput>
              <TextInput
                id="email"
                name="email"
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email ? (
                <span className="text-error-500 mt-2">{errors.email}</span>
              ) : null}
            </S.FieldControl>
          </div>
          <div className="flex gap-3.5 mb-3.5">
            <div className="flex flex-col w-full">
              <LabelInput required>Phone number</LabelInput>
              <BaseInputMask
                id="phone"
                name="phone"
                value={values?.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="+1 (999) 999-9999"
                className="border w-full h-[46px] rounded-md pl-5"
              />
              {errors.phone && touched.phone ? (
                <span className="text-error-500">{errors.phone}</span>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col mb-6 w-full">
            <LabelInput required>Add to segment</LabelInput>
            <S.BorderlessFormikField
              disabled={showRmFields && !isBooks}
              component={SelectMultiField}
              name="segments"
              placeholder="Select segment"
              type="text"
              options={segmentsData}
              maxCount="8"
              selectedSegment={values && values?.segments && values?.segments.map((d) => d.id || d)}
            />
            {errors.segments && touched.segments ? (
              <span className="text-error-500 mt-2">{errors.segments}</span>
            ) : null}
          </div>
          {showRmFields && (
            <div className="flex flex-row items-center gap-3.5 font-semibold">
              <SwitchButton
                id="isRm"
                name="isRm"
                checked={values?.isRm}
                onChange={showRmFields && isBooks && setIsRm}
              />
              <span>For Referral Magazine (RM) shipments</span>
            </div>
          )}
          {values?.isRm || !showRmFields ? (
            <>
              <div className="mt-6 flex flex-col">
                <LabelInput required={showRmFields}>Country</LabelInput>
                <div>
                  <S.BorderlessFormikField
                    component={SelectField}
                    name="country"
                    placeholder="Select"
                    type="text"
                    options={countryList}
                    $hasError={errors.country && touched.country}
                    onChange={handleCountryChange}
                    onBlur={handleCountryChange}
                    value={values?.country}
                  />
                </div>
                {errors.country && touched.country ? (
                  <span className="text-error-500 mt-2">{errors.country}</span>
                ) : null}
              </div>
              <div className="flex flex-col mt-3.5">
                <LabelInput required={showRmFields}>Street address</LabelInput>
                <TextInput
                  id="address1"
                  name="address1"
                  placeholder="Street address"
                  $hasError={errors.address1 && touched.address1}
                  value={values?.address1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="relative"
                  placeholderIcon={PinIcon}
                />
                {errors.address1 && touched.address1 ? (
                  <span className="text-error-500 mt-2">{errors.address1}</span>
                ) : null}
              </div>
              <div className="flex flex-col mt-3.5">
                <LabelInput>Apartment, suite, unit, building, etc.</LabelInput>
                <TextInput
                  id="address2"
                  name="address2"
                  placeholder=""
                  $hasError={errors.address2 && touched.address2}
                  value={values?.address2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.address2 && touched.address2 ? (
                  <span className="text-error-500 mt-2">{errors.address2}</span>
                ) : null}
              </div>
              <div className="flex flex-col mt-3.5">
                <LabelInput required={showRmFields}>City</LabelInput>
                <TextInput
                  id="city"
                  name="city"
                  placeholder=""
                  value={values?.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  $hasError={errors.city && touched.city}
                />
                {errors.city && touched.city ? (
                  <span className="text-error-500 mt-2">{errors.city}</span>
                ) : null}
              </div>
              <div className="flex flex-col mt-3.5">
                <LabelInput required={showRmFields}>State/Province</LabelInput>
                <S.SelectContainer>
                  <S.BorderlessFormikField
                    component={SelectField}
                    name="state"
                    placeholder="Select"
                    options={stateList}
                    value={values?.state}
                    $hasError={errors.state && touched.state}
                  />
                </S.SelectContainer>
              </div>
              <div className="flex flex-col mt-3.5">
                <LabelInput required={showRmFields}>ZIP/Postal code</LabelInput>
                <TextInput
                  id="zip"
                  name="zip"
                  placeholder=""
                  value={values?.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  $hasError={errors.zip && touched.zip}
                />
                {errors.zip && touched.zip ? (
                  <span className="text-error-500 mt-2">{errors.zip}</span>
                ) : null}
              </div>
            </>
          ) : (
            <></>
          )}
        </Form>
      </div>
    </div>
  );
};

export default EditLeads;

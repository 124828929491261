import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import get from 'lodash/get';
import { useFormikContext } from 'formik';
import { Alert, Tooltip } from 'flowbite-react';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';

import { ROUTE_REFERRAL_MARKETING_MAGAZINE_EDITOR } from 'modules/v2/routes/constants';
import { getGeneratedMagazineMonth } from 'modules/api';
import { Link } from 'modules/v2/draft/components';
import AlertWarning from 'modules/v2/common/components/SvgIcon/AlertWarning';
import Spinner from 'modules/v2/common/components/SvgIcon/Spinner';

import { formatDateTime } from 'modules/v2/common/utils';
import { isAdminEditing } from 'modules/v2/utils';
import QuestionCircle from 'modules/v2/common/components/SvgIcon/QuestionCircle';
import {
  magazineStatus,
  monthsList,
  params as defaultParams,
  rmMagazineStatus,
} from '../../constants';

const GeneratedMagazine = ({ history }) => {
  const [sendToPrint, setSendToPrint] = useState(false);
  const [currentMagazineStatus, setCurrentMagazineStatus] = useState('');
  const { setFieldValue } = useFormikContext();
  const [dataWithParmsInterval, setDataWithParmsInterval] = useState(3000);
  const params = defaultParams;

  const getGeneratedMagazineQueryKey = ['getGeneratedMagazineMonth', params.year, params.month];
  const getGeneratedMagazineQueryKeyNoParams = ['getGeneratedMagazineMonth'];
  const getGeneratedMagazineFn = getGeneratedMagazineMonth;

  const { isLoading: loadingGeneratedMagazine, data: dataWithParams, isError } = useQuery(
    getGeneratedMagazineQueryKey,
    getGeneratedMagazineFn,
    {
      retry: 2,
      refetchInterval: dataWithParmsInterval,
      keepPreviousData: true,
      onSuccess: (data) => setCurrentMagazineStatus(get(data, 'magazine.status')),
    },
  );

  // DON'T REMOVE COMMENT FOR SANITY
  // If all else fails, try to fetch the magazine without params
  // Sometimes a user does not have a magazine for current month,
  // so we get all magazines and pick the first one
  const { data: dataWithoutParams = {} } = useQuery(
    getGeneratedMagazineQueryKeyNoParams,
    getGeneratedMagazineFn,
    {
      enabled: isError,
      retry: 2,
      onSuccess: (data) => {
        setDataWithParmsInterval(false);
        setCurrentMagazineStatus(get(data[0], 'magazine.status'));
      },
    },
  );

  const magazineData = dataWithParams ?? dataWithoutParams;
  const generatedMagazine = Array.isArray(magazineData) ? magazineData[0] : magazineData;

  useEffect(() => {
    if (currentMagazineStatus === 'SENT_FOR_PRINTING') {
      setSendToPrint(true);
    }
  }, [currentMagazineStatus]);

  const handleEditMagazine = () => {
    setFieldValue('hasPreviousData', true);

    const parameters = {
      month: monthsList[get(generatedMagazine, 'magazine.month')],
      year: get(generatedMagazine, 'magazine.year'),
    };

    const routeToRedirect = ROUTE_REFERRAL_MARKETING_MAGAZINE_EDITOR.replace(
      ':month',
      parameters.month,
    ).replace(':year', parameters.year);

    return history.push(routeToRedirect);
  };

  const isMagazineSentToPrinting = isAdminEditing()
    ? false
    : currentMagazineStatus === rmMagazineStatus.SENT_FOR_PRINTING;

  if (loadingGeneratedMagazine) {
    return (
      <div className="w-full h-svh flex items-center justify-center">
        <div className="flex flex-col gap-2 items-center justify-center bg-white w-80 h-52 rounded-xl shadow-box py-8">
          <Spinner />
          <span className="text-neutral-800 text-base font-bold mt-6">
            Generating digital magazine...
          </span>
          <span className="text-neutral-500 text-sm font-medium">
            Please wait while we generate your magazine.
          </span>
        </div>
      </div>
    );
  }

  if (!generatedMagazine?.id) {
    return (
      <div className="relative w-full h-full min-h-3/4 overflow-hidden">
        <div className="text-neutral-800 text-2xl font-bold leading-7 mb-6">Active Magazine</div>
        <div className="w-full h-full flex flex-col justify-center bg-white rounded-lg p-6 shadow-box">
          <div className="frame_280 flex flex-col justify-center items-center self-stretch gap-4">
            <div className="frame_824 flex flex-col justify-center items-center self-stretch">
              <div className="frame_191 flex flex-col items-center self-stretch">
                <div className="self-stretch text-neutral-800 text-center  text-base font-semibold">
                  No active magazine for this month of {params.month} {params.year}
                </div>
                <div className="text-neutral-600 text-centertext-sm font-medium leading-[1.4375rem]">
                  You haven't ordered any magazines yet
                </div>
              </div>
            </div>
            <Button type="primary" buttonType="button" onClick={handleEditMagazine}>
              Order Magazine
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (generatedMagazine?.id && generatedMagazine?.pageStatus === 'ERROR') {
    return (
      <div className="relative w-full h-full min-h-3/4 overflow-hidden">
        <div className="text-neutral-800 text-2xl font-bold leading-7 mb-6">Active Magazine</div>
        <div className="w-auto h-full bg-white rounded-lg p-6 shadow-box">
          <Alert color="failure">
            <div className="w-full flex gap-4">
              <AlertWarning /> Something went wrong while fetching generated Magazine
            </div>
          </Alert>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="relative w-full h-full min-h-3/4 overflow-hidden">
        <div className="text-neutral-800 text-2xl font-bold leading-7 mb-6">Active Magazine</div>
        {sendToPrint && (
          <Alert color="warning" className="bg-secondary-orange-50 my-6">
            <div className="w-full flex gap-4">
              <AlertWarning />
              <span className="font-medium text-notificationWarning">
                You can only send revisions once. If you wish to do more revisions, please contact
                <a
                  href="https://authorify.com/contact-us/"
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold text-notificationWarning hover:text-notificationWarning underline pl-1"
                >
                  support
                </a>
              </span>
            </div>
          </Alert>
        )}
        <div className="w-full h-full flex gap-4 max-[1280px]:flex-wrap">
          <div className="content w-3/4 max-[1280px]:w-full h-full bg-white rounded-lg p-6 shadow-box">
            <div className="relative w-full flex justify-between items-center mb-3.5 max-[500px]:flex-col">
              <div className="text-neutral-800 text-base font-semibold">
                Your {params.month} {params.year} magazine
              </div>
              <Button
                color="light"
                onClick={handleEditMagazine}
                disabled={!isMagazineSentToPrinting}
              >
                Edit magazine
              </Button>
            </div>
            <div className="h-550">
              <afy-flip-book
                key="magazine-step-pdf"
                pdfurl={generatedMagazine?.contentUrl || generatedMagazine?.url}
              />
            </div>
          </div>
          <div className="w-1/3 max-[1280px]:w-full flex flex-col gap-4 max-[1280px]:flex-row">
            <div className="w-full flex flex-col gap-6">
              <div className="w-full flex flex-col items-start gap-5 bg-white shadow-box rounded-lg self-stretch p-6">
                <div className="w-full flex gap-4 justify-between items-center">
                  <div className="text-neutral-800 text-base font-semibold">Status</div>
                  <div
                    className={`flex justify-center items-center ${
                      sendToPrint
                        ? 'bg-success-50 text-success-600'
                        : 'bg-secondary-orange-50 text-secondary-orange-600'
                    } px-3 py-1 rounded-md`}
                  >
                    <span className="font-semibold">{magazineStatus[currentMagazineStatus]}</span>
                  </div>
                </div>
                <div className="border-t w-full flex flex-col items-center bg-white shadow-box rounded-lg" />
                <div className="w-full flex gap-4 justify-between items-center">
                  <div className="text-neutral-800 text-base font-semibold">Last edited</div>
                  <div className="overflow-hidden text-neutral-400 text-ellipsis  text-sm font-medium">
                    {formatDateTime(generatedMagazine?.updatedAt)}
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-start gap-10 bg-white shadow-box rounded-lg self-stretch p-6">
                <div className="w-full">
                  <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                    Lead capture website
                    <Tooltip
                      content="Generate more leads and grow your business. You'll get notified instantly whenever you receive a new lead."
                      Style="dark"
                      className="max-w-[250px] text-sm text-neutral-200"
                    >
                      <QuestionCircle />
                    </Tooltip>
                  </h5>
                  <Link key="landingPage" id="landingPage" href={generatedMagazine?.pageUrl} />
                </div>
                <div className="w-full">
                  <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                    Digital magazine
                    <Tooltip
                      content="Easily showcase your expertise and build trust with clients who are unable to meet with you in person."
                      Style="dark"
                      className="max-w-[250px] text-sm text-neutral-200"
                    >
                      <QuestionCircle />
                    </Tooltip>
                  </h5>
                  <Link
                    key="digitalMagazine"
                    id="digitalMagazine"
                    href={generatedMagazine?.bookUrl}
                  />
                </div>
                {sendToPrint && (
                  <div className="w-full">
                    <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                      Flipping book
                      <Tooltip
                        content="Your digital magazine flipping book"
                        Style="dark"
                        className="max-w-[250px] text-sm text-neutral-200"
                      >
                        <QuestionCircle />
                      </Tooltip>
                    </h5>
                    <Link
                      key="digitalMagazine"
                      id="digitalMagazine"
                      href={generatedMagazine?.flippingBookUrl}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneratedMagazine;

import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { OtpInput } from 'modules/v2/common/AtomicDesign/molecules';
import { notification, cn } from 'modules/v2/common/utils';
import { useState, useEffect } from 'react';
import { loginViaOtp } from 'modules/api/auth';
import { useMutation } from 'react-query';
import { setItem } from 'modules/v2/utils/legacy';
import { Loader } from 'modules/v2/common/components';
import { TIME_TO_RESEND_OTP } from './utils';

function SetEmailValidation({ auth, history, reSendOtpRequest }) {
  const [timeTracker, setTimeTracker] = useState(TIME_TO_RESEND_OTP);
  const [otpCode, setOtpCode] = useState(null);
  const [hasErrors, setHasErrors] = useState(false);
  const disableSendRequest = timeTracker !== 0;
  useEffect(() => {
    const intervalTime = setInterval(() => {
      if (timeTracker > 0) {
        setTimeTracker(timeTracker - 1);
      }
    }, 1000);
    return () => clearInterval(intervalTime);
  }, [timeTracker]);

  const { mutate: loginOtpMutation, isLoading } = useMutation(
    ({ email, code }) => loginViaOtp({ email, code }),
    {
      onSuccess: ({ data }) => {
        const userDataUpdated = data?.properties;
        userDataUpdated.welcomeJourneyEligible = true;

        setItem('token', data.token);
        setItem('refresh_token', data.refresh_token);
        setItem('userData', userDataUpdated);

        history.push('/welcome-journey');
      },
      onError: () => {
        setHasErrors(true);
      },
    },
  );

  const handleOtpCode = () => {
    loginOtpMutation({ email: auth.userEmail, code: otpCode });
  };

  const handleSendEmail = () => {
    if (disableSendRequest) return;
    setTimeTracker(TIME_TO_RESEND_OTP);
    notification.success({
      description: 'Link successfully sent to your email',
    });
    reSendOtpRequest();
  };

  return isLoading ? (
    <Loader height="50vh" title="Logging in" />
  ) : (
    <div className="w-full flex flex-col items-center">
      <h2 className="font-extrabold text-2xl mb-3.5">Enter OTP code</h2>
      <span className="text-neutral-500 text-center font-normal mb-[30px]">
        Enter the code we sent over to your registered email at {auth.userEmail}.
      </span>

      <div className="flex flex-col items-center gap-[14px] max-w-[316px]">
        <OtpInput length={6} onSubmit={setOtpCode} hasErrors={hasErrors} />
        {hasErrors && (
          <div className="text-sx font-semibold text-danger-500">
            Sorry, we are not able to verify the code. Please make sure you input the correct code.
          </div>
        )}
      </div>

      <Button
        size="lg"
        full
        className="mt-[60px] mb-3.5"
        disabled={isLoading}
        onClick={handleOtpCode}
      >
        Continue
      </Button>

      <span className="text-neutral-500 font-medium">
        Did not receive the code?
        <span
          className={cn('underline ml-1', {
            'cursor-not-allowed': timeTracker > 0,
            'cursor-pointer': timeTracker === 0,
          })}
          onClick={handleSendEmail}
        >
          Resend
          {timeTracker > 0 && <span>({timeTracker})</span>}
        </span>
      </span>
    </div>
  );
}

export default SetEmailValidation;

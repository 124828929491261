import * as Yup from 'yup';

const regexUSA = /(^[0-9]{5}(?:-[0-9]{4})?$)/;
const regexCanada = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]●?[0-9][A-Z][0-9]$/;

const zipUSA = {
  is: 'USA',
  then: Yup.string().matches(regexUSA, 'Postal Code for USA needs to be valid'),
};

const zipCanada = {
  is: 'Canada',
  then: Yup.string().matches(regexCanada, 'Postal Code to Canada needs to be valid'),
};

const pincode = Yup.string()
  .when('isRm', {
    is: true,
    then: Yup.string().required('ZIP/Postal code is required'),
  })
  .when('country', zipUSA)
  .when('country', zipCanada);

export default pincode;

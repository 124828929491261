import { useState } from 'react';
import { useQuery } from 'react-query';
import get from 'lodash/get';

import { Tooltip } from 'flowbite-react';
import { Link } from 'modules/v2/draft/components';
import QuestionCircle from 'modules/v2/common/components/SvgIcon/QuestionCircle';

import {
  getGeneratedMagazineMonth,
  sendToPrint as sendMagazineToPrint,
  createSetupTicket,
} from 'modules/api';

import { params as defaultParams } from '../../constants';

const PreviousMagazineDetails = ({ selectedMagazine, onBack }) => {
  const [currentMagazineStatus, setCurrentMagazineStatus] = useState('');
  const [dataWithParmsInterval, setDataWithParmsInterval] = useState(3000);
  const params = defaultParams;

  const getGeneratedMagazineQueryKey = ['getGeneratedMagazineMonth', params.year, params.month];
  const getGeneratedMagazineQueryKeyNoParams = ['getGeneratedMagazineMonth'];
  const getGeneratedMagazineFn = getGeneratedMagazineMonth;

  const { isLoading: loadingGeneratedMagazine, data: dataWithParams, isError } = useQuery(
    getGeneratedMagazineQueryKey,
    getGeneratedMagazineFn,
    {
      retry: 2,
      refetchInterval: dataWithParmsInterval,
      keepPreviousData: true,
      onSuccess: (data) => setCurrentMagazineStatus(get(data, 'magazine.status')),
    },
  );

  // DON'T REMOVE COMMENT FOR SANITY
  // If all else fails, try to fetch the magazine without params
  // Sometimes a user does not have a magazine for current month,
  // so we get all magazines and pick the first one
  const { data: dataWithoutParams = {} } = useQuery(
    getGeneratedMagazineQueryKeyNoParams,
    getGeneratedMagazineFn,
    {
      enabled: isError,
      retry: 2,
      onSuccess: (data) => {
        setDataWithParmsInterval(false);
        setCurrentMagazineStatus(get(data[0], 'magazine.status'));
      },
    },
  );

  const magazineData = dataWithParams ?? dataWithoutParams;
  const generatedMagazine = Array.isArray(magazineData) ? magazineData[0] : magazineData;

  return (
    <>
      <div className="text-neutral-800 text-2xl font-extrabold leading-7 mb-6 capitalize">
        {selectedMagazine.month} {selectedMagazine.year}
      </div>
      <div className="w-full h-full flex flex-col xl:flex-row gap-4">
        <div className="content xl:w-3/4 w-full h-full bg-white rounded-lg p-6 shadow-box">
          <div className="relative w-full flex justify-between items-center mb-3.5">
            <div className="text-neutral-800 text-base font-semibold">Your magazine</div>
          </div>
          <div className="h-[550px]">
            <afy-flip-book key="generated-magazine-pdf" pdfurl={selectedMagazine.contentUrl} />
          </div>
        </div>
        <div className="xl:w-1/3 w-full flex flex-col gap-4">
          <div className="w-full flex flex-col gap-6">
            <div className="w-full flex flex-col items-start gap-10 bg-white boxshadow-notification rounded-lg self-stretch p-6">
              <div className="w-full">
                <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                  Lead capture website
                  <Tooltip
                    content="Generate more leads and grow your business. You'll get notified instantly whenever you receive a new lead."
                    Style="dark"
                    className="max-w-[250px] text-sm text-neutral-200"
                  >
                    <QuestionCircle />
                  </Tooltip>
                </h5>
                <Link key="landingPage" id="landingPage" href={generatedMagazine?.pageUrl} />
              </div>
              <div className="w-full">
                <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                  Digital Magazine{' '}
                  <Tooltip
                    content="Easily showcase your expertise and build trust with clients who are unable to meet with you in person."
                    Style="dark"
                    className="max-w-[250px] text-sm text-neutral-200"
                  >
                    <QuestionCircle />
                  </Tooltip>
                </h5>
                <Link
                  key="digitalMagazine"
                  id="digitalMagazine"
                  href={generatedMagazine?.bookUrl}
                />
              </div>
              <div className="w-full">
                <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                  Flipping book{' '}
                  <Tooltip
                    content="Your digital magazine flipping book"
                    Style="dark"
                    className="max-w-[250px] text-sm text-neutral-200"
                  >
                    <QuestionCircle />
                  </Tooltip>
                </h5>
                <Link
                  key="digitalMagazine"
                  id="digitalMagazine"
                  href={generatedMagazine?.flippingBookUrl}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviousMagazineDetails;

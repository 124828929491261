import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Loader } from 'modules/v2/common/components';
import { generateDraftFromBook } from 'modules/api';
import { getRouteDraftView } from 'modules/v2/draft/routes/navigation';
import { getRouteEditDraftCover } from 'modules/v2/editor/routes/navigation';
import {
  BOOK_NAVIGATION
} from 'modules/v2/pages/WelcomeJourney/utils/constants';

const GenerateWelcomeDraft = () => {
  const { id, flowPath } = useParams();
  const history = useHistory();

  const { isLoading: isFetchingDraft } = useQuery(
    'generateDraftFromBook',
    () => generateDraftFromBook({ bookId: id }),
    {
      onSuccess: async (draft) => {
        const generatedDraftId = draft?.data?.draftId;
        let draftPath = getRouteDraftView(generatedDraftId);
        if(flowPath === BOOK_NAVIGATION.CUSTOMIZE){
          draftPath = getRouteEditDraftCover(generatedDraftId);
        }
        history.push(`${draftPath}?welcomeJourney`);
      },
    },
  );

  return <Loader title="Generating Book" />;
};

export default GenerateWelcomeDraft;

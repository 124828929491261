import { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';
import isEmpty from 'lodash/isEmpty';
import {
  ListingDetailsComponent,
  ReferralPartnerComponent,
  RenderOptionsComponent,
  TestimonialsComponent,
} from '../..';
import { backInsideCoverOptionsFormFields, backInsideCoverOptionsHeading } from '../constants';

const BackInsideCover = ({ backInsideCover, handleImageLightBox, updateMagazineData }) => {
  const [showListingImageModal, setShowListingImageModal] = useState(false);

  const {
    magazine = {},
    handleUpdateMagazine,
    magazineDataFormik,
    previousMagazineData,
    setFormikContext,
  } = updateMagazineData || {};

  const selectedCoverOption = get(magazine, 'backInsideCover');
  const backInsideCoverOptionValues = get(magazine, 'backInsideCoverOptionValues');

  const lastSelectedCoverStripOption = useRef(selectedCoverOption?.formKeyword);

  useEffect(() => {
    const currentSelectedOption = get(selectedCoverOption, 'formKeyword');
    const lastSelectedOption = get(lastSelectedCoverStripOption, 'current');
    if (lastSelectedOption !== currentSelectedOption) {
      const currentValues = get(magazineDataFormik, 'values.backInsideCover._formFields');
      lastSelectedCoverStripOption.current = currentSelectedOption;
      const initialValueOfSelctedOptionForm =
        backInsideCoverOptionsFormFields[currentSelectedOption];
      magazineDataFormik.setFieldValue('backInsideCover._formFields', {
        ...initialValueOfSelctedOptionForm,
        ...currentValues,
      });
    }
  }, [selectedCoverOption, magazineDataFormik]);

  const toggleShowListingImageModal = () => {
    setShowListingImageModal(!showListingImageModal);
  };

  const onCancelListingImageModal = () => {
    setShowListingImageModal(false);
  };

  const handleCoverOptionChange = (option) => {
    handleUpdateMagazine(option, 'backInsideCover');
  };

  const onHandleResetFormFields = () => {
    if (!isEmpty(previousMagazineData)) {
      const insideCoverContent = previousMagazineData.selections.find(
        (selection) => selection.page === 3,
      );
      const { formKeyword } = insideCoverContent;
      const regex = /option-(\d)/;
      const match = formKeyword.match(regex);
      if (match.length > 0) {
        const optionSelected = match[0].replace('-', ' ');
        const option = backInsideCover.find(
          (opt) => opt.name.toLowerCase() === optionSelected.toLowerCase(),
        );
        handleUpdateMagazine(option, 'backInsideCover');
      }
    } else {
      const initialValueOfSelctedOptionForm =
        backInsideCoverOptionsFormFields['backInsideCover-option-1'];
      handleUpdateMagazine(backInsideCover[0], 'backInsideCover');
      magazineDataFormik.setFieldValue(
        'backInsideCover._formFields',
        initialValueOfSelctedOptionForm,
      );
    }
    setFormikContext('current', -1);
  };

  const allowedResolutionsTextBackInsideCover = { width: 1440, height: 960 };

  const renderAdditionalData = (type) => {
    switch (type) {
      case 'backInsideCover-option-2':
        return (
          <TestimonialsComponent
            step="backInsideCover"
            data={backInsideCoverOptionValues}
            formikHandler={magazineDataFormik}
          />
        );
      case 'backInsideCover-option-3':
        return (
          <ListingDetailsComponent
            imageDimensions={allowedResolutionsTextBackInsideCover}
            step="backInsideCover"
            hasInputFields
            formikHandler={magazineDataFormik}
            isOpen={showListingImageModal}
            onCancel={onCancelListingImageModal}
            propertyKey="listingImageJustSold"
            handleOpenModal={toggleShowListingImageModal}
            uploadImageType="backInsideCoverImageOption3"
            presentImageLocation={get(
              magazineDataFormik,
              'values.backInsideCover._formFields.listingImageJustSold',
            )}
          />
        );
      case 'backInsideCover-option-4':
        return (
          <ListingDetailsComponent
            imageDimensions={allowedResolutionsTextBackInsideCover}
            step="backInsideCover"
            hasInputFields
            hasTextArea
            formikHandler={magazineDataFormik}
            propertyKey="listingImageJustListed"
            isOpen={showListingImageModal}
            onCancel={onCancelListingImageModal}
            presentImageLocation={get(
              magazineDataFormik,
              'values.backInsideCover._formFields.listingImageJustListed',
            )}
            handleOpenModal={toggleShowListingImageModal}
            uploadImageType="backInsideCoverImageOption4"
            hasName
          />
        );
      case 'backInsideCover-option-6':
        return (
          <ReferralPartnerComponent
            step="backInsideCover"
            hasInputFields
            hasTextArea
            data={backInsideCoverOptionValues}
            formikHandler={magazineDataFormik}
            isOpen={showListingImageModal}
            onCancel={onCancelListingImageModal}
            handleOpenModal={toggleShowListingImageModal}
          />
        );
      default:
        return null;
    }
  };

  const selectedCoverStripOptionName = get(selectedCoverOption, 'name');
  const selectedOptionTitle =
    backInsideCoverOptionsHeading[get(selectedCoverOption, 'formKeyword')];

  return (
    <div>
      <div className="flex h-11 mt-[37px] items-center gap-5 self-stretch font-medium text-sm text-neutral-800">
        Select back inside cover
      </div>
      <RenderOptionsComponent
        onImageClick={handleImageLightBox}
        optionsList={backInsideCover}
        handleChange={handleCoverOptionChange}
        selectedOption={selectedCoverOption}
        setMaxWidth="182px"
        setMaxHeight="276px"
      />
      <div className="text-neutral-800 text-base font-semibold mt-8 mb-6">
        {selectedOptionTitle && `${selectedCoverStripOptionName}: ${selectedOptionTitle}`}
      </div>
      <div>{renderAdditionalData(selectedCoverOption?.formKeyword)}</div>
      <div className="flex gap-2 justify-start mt-6">
        <Button color="light" onClick={onHandleResetFormFields}>
          Cancel
        </Button>
        <Button color="dark" buttonType="submit" onClick={magazineDataFormik.handleSubmit}>
          Save changes
        </Button>
      </div>
    </div>
  );
};

export default BackInsideCover;

import { CoverImage } from 'modules/editor/components';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';
import { getRouteDraftView } from 'modules/v2/draft/routes/navigation';

const OrderList = ({ bookTitle, coverImage, orderId, quantity, draftId, history }) => {
  const handleNavigation = () => {
    const draftPath = getRouteDraftView(draftId);
    const url = `${draftPath}?welcomeJourney&turnoff`;
    history.push(url);
  };

  return (
    <div className="flex flex-col gap-2 p-2 rounded-lg mb-1">
      <div className="flex">
        <div className="w-[88px] h-[108px]">
          <CoverImage url={coverImage} />
        </div>
        <div className="flex flex-col gap-4 ml-3.5 w-[377px] h-[194px]">
          <div className="flex w-auto max-w-[160px] justify-center items-center border border-neutral-200 px-2 rounded-md">
            Order# {orderId}
          </div>
          <div className="flex gap-4">
            <div>{bookTitle}</div>
            <div className="flex w-auto max-w-[160px] justify-center items-center border border-neutral-200 text-sm font-semibold px-2 rounded-md">
              Qty: {quantity}
            </div>
          </div>
          <Button color="outlined" size="sm" onClick={handleNavigation}>
            View details
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrderList;

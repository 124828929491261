import axios from 'axios';
import qs from 'query-string';
import { merge } from 'lodash-es';

import env from 'environments';
import { getItem } from 'modules/dashboard/utils/legacy';

const instance = axios.create({
  baseURL: env.BBA_URL,
  paramsSerializer: qs.stringify,
});

instance.interceptors.request.use((config) => {
  const refreshtoken = getItem('refresh_token');

  return merge({}, config, {
    headers: {
      refreshtoken,
    },
  });
});

export const getInstanceWithRefreshToken = () => instance;

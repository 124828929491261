import * as color from 'modules/v2/common/theme/color';
import styled from 'styled-components';
import { CancelButton, PrimaryButton } from 'modules/v2/common/components';

const PreviewWrapper = styled.div`
  position: absolute;
  opacity: 0;
  top: 50px;
  left: 4px;
  height: 200px;
  width: 205px;
  z-index: 1;
  color: ${color.White};
  text-align: center;
  padding: 20px;
  font-size: 16px;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.9);
`;

const PreviewModalBody = styled.div`
  padding-top: 40px;
  height: 800px;
`;

const Preview = styled.div`
  position: relative;

  &:hover {
    ${PreviewWrapper} {
      opacity: 1;
    }
  }
`;

const Image = styled.img`
  height: 220px;
  width: 100%;
  display: block;
  border-radius: 3px;
  transition: 0.3s ease;
  background: ${color.GrayBorderColor};
`;

const GuideContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  height: 100%;
  width: 100%;
`;

const ButtonGroup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const WhiteButton = styled(CancelButton)`
  .ant-btn {
    margin: 0 !important;
    padding: 10px 14px 10px 12px !important;
    height: 38px !important;
  }
  border-radius: 6px !important;
  color: ${color.Black} !important;
  :hover {
    background-color: #fff !important;
    border: none;
    opacity: 100;
  }
`;

const Button = styled(PrimaryButton)`
  .ant-btn {
    margin: 0 !important;
    padding: 10px 14px !important;
    height: 38px !important;
  }
  background: ${color.primary500} !important;
  color: ${color.Black} !important;
  border-radius: 6px !important;
  :hover {
    background-color: ${color.primary500} !important;
    border: none;
    opacity: 100;
  }
`;

const ImageContainer = styled.div`
  position: relative;

  &:hover {
    ${ButtonContainer} {
      opacity: 1;
    }
  }
`;

export default {
  Preview,
  PreviewWrapper,
  PreviewModalBody,
  Image,
  GuideContainer,
  ButtonContainer,
  ImageContainer,
  Button,
  WhiteButton,
  ButtonGroup,
};

import * as Yup from 'yup';
import validation from 'modules/v2/common/validationSchema';

const ValidationSchema = Yup.object().shape({
  firstName: validation.firstName,
  lastName: validation.lastName,
  email: validation.email,
  phone: validation.phoneNumber,
  address1: validation.address1,
  city: validation.city,
  country: validation.country,
  state: validation.state,
  zip: validation.pincode,
  segments: validation.campaignSegments,
});

export default ValidationSchema;

import qs from 'query-string';

import { getInstance } from './instanceScheduler';

const instance = getInstance();

export const getAvailableTimeSlots = async ({ start, end }) => {
  const baseUrl = 'coaches/events/free';

  const queryParams = { start, end, coachingSegment: 'REALTOR' };

  const queryString = qs.stringify(queryParams);
  const url = `${baseUrl}?${queryString}`;

  try {
    const response = await instance.get(url);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAvailableTimeSlotsForDentist = async ({ start, end }) => {
  const baseUrl = 'coaches/events/free';
  const queryParams = { start, end, coachingSegment: 'DENTIST' };

  const queryString = qs.stringify(queryParams);
  const url = `${baseUrl}?${queryString}`;

  try {
    const response = await instance.get(url);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const scheduleCoachingCall = (payload) => {
  const url = 'coaches/events';

  return instance.post(url, payload);
};

export const getscheduledCoachingCall = (email) => {
  const url = `coaches/events/get-member-event/${email}`;
  return instance.get(url);
};
